import classNames from 'classnames';
import styles from './styles/LoginButtons.module.scss';
import LoginButton from "./LoginButton";

const loginButtonsContainerClassName = classNames({
    [styles.loginButtonsContainer]: true,
});

export default function LoginButtons() {

    return (
        <div>
            <ul className={loginButtonsContainerClassName}>
                {
                    ['google', 'office', 'facebook'].map((item, i) => {
                        return (
                            <li key={i}>
                                <LoginButton type={item}/>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
