import {observer} from "mobx-react";
import styles from "./styles/NewsBlock.module.scss"
import BlockTitle from "../_BlockTitle";
import ViewMoreButton from "../ViewMoreButton";
import React, {useContext, useRef, useState} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";
import {CSSTransition} from "react-transition-group";
import {IReactiveNewsContentItem, ReactiveContentItem} from "../../../../stores/SchoolFeedStore";
import classNames from "classnames";
import {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";
import {
    getTerraceTypeFromContentItem,
    SupportedContentItemTypes,
} from "../../../schoolfeed/PostManager/SchoolFeedPostManager";
import {useCreateContentItem} from "../../../../hooks/useCreateContentItem";
import NewsSelector from "./NewsSelector";
import {runInAction, values} from "mobx";
import {Toggle} from "../../SchoolBlocks/EditBlockComponents/EditBlockInputs/Toggle";
import {ITerraceProps} from "../Terrace";

type IReactiveNewsTerraceObj = Omit<INewsTerrace, "content_data"> & (
    {
        content_data: {
            [org_id: string]: {
                path: string,
                title: string,
                items: IReactiveNewsContentItem[],
                is_more: boolean,
            }
        },
    })

interface NewsBlockProps extends ITerraceProps {
    blockObj: IReactiveNewsTerraceObj,
}

const visibilityTransitionClassNames = {
    enter: styles.transitionEnter,
    appear: styles.transitionAppear,
    enterActive: styles.transitionEnterActive,
    appearActive: styles.transitionAppearActive,
    enterDone: styles.transitionEnterDone,
    appearDone: styles.transitionAppearDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

const NewsBlock = observer((props: NewsBlockProps) => {

    const {organizationStore, sidebarStore, gridStore} = useContext(StoreContext);
    const transitionRef = useRef(null);

    const {handleSave} = useCreateContentItem();

    const showDistrictOnSchoolSite = organizationStore.currentOrganization.type === 'school' && organizationStore.organization.district

    const [currentOrgId, setCurrentOrgId] = useState<string>(() => {
        const keys = Array.from(Object.keys(props.blockObj.content_data));

        if (keys.length === 2) {
            if (props.blockObj.content_data[keys[0]].items.length > 0) {
                return keys[0];
            } else {
                return keys[1];
            }
        } else {
            return keys[0];
        }
    });

    let content: Array<IReactiveNewsContentItem> = props.blockObj.content_data[currentOrgId].items;

    const newsOptions = Object.entries(props.blockObj.content_data).map(([org_id, data]) => {
        return ({
            value: org_id,
            label: data.title,
        })
    });

    const handleChangeOrg = (orgId) => {
        setCurrentOrgId(orgId)
    };

    const onClick = () => {
        sidebarStore.setSidebar(
            {
                view: "SchoolFeedPost",
                sbtype: SupportedContentItemTypes.NEWS,
                handleSave: handleSave,
            }
        )
    }

    const newsBlockDropDownItems: EditDropDownItem[] = [
        {
            title: 'Add News Post',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-plus"/>,
            onClick: onClick,
        },
    ]

    const newsTerraceTitle = () => {
        if (organizationStore.organization.type === 'district' || showDistrictOnSchoolSite) {
            return 'District News'
        } else if (organizationStore.organization.type === 'school') {
            return 'School News'
        }
    }

    const titleandEditPencilClassName = classNames({
        [styles.titleAndEditPencil]: organizationStore.currentOrganization.type !== 'district',
    })

    const customTitleComponent = currentOrgId === organizationStore.currentOrganization.id ? undefined
        : <div className={styles.titleContainer}>
            <div className={`${styles.editableTitle} ${styles.editableTitleParent}`}>
                <h2>
                    {newsTerraceTitle()}
                </h2>
            </div>
        </div>

    function handleDelete(contentItem) {
        runInAction(() => {
            const terraceType = getTerraceTypeFromContentItem(contentItem);
            const terrace = values(gridStore.blocks).find(b => b.type === terraceType);
            if (terrace) {
                const index = terrace.content_data[`${organizationStore.currentOrganization.id}`].items.findIndex(item => item.id === contentItem.id);
                if (index !== -1) {
                    terrace.content_data[`${organizationStore.currentOrganization.id}`].items.splice(index, 1);
                }
            }
        })
    }

    return (
        <div className={styles.newsBlockContainer}>
            <div id={'blockTitle'} className={titleandEditPencilClassName}>
                <BlockTitle blockObj={props.blockObj}
                            aboveTheFold={props.aboveTheFold}
                            blockTitleContainerClassName={styles.blockTitleContainerClassName}
                            dropDownItems={newsBlockDropDownItems}
                            customTitleComponent={customTitleComponent}
                            showViewMore={false}
                />
                {(organizationStore.currentOrganization.type !== 'district' || showDistrictOnSchoolSite) && <Toggle
                    value={currentOrgId}
                    onChange={handleChangeOrg}
                    options={newsOptions}
                    className={styles.toggleClassName}
                />}
            </div>
            <div className={styles.transitionWrapper}>
                <CSSTransition nodeRef={transitionRef} key={currentOrgId} appear in={true} classNames={visibilityTransitionClassNames}
                               timeout={250}>
                    <div ref={transitionRef} className={styles.transitionContainer}>
                        {content.length === 0 ?
                            <div className={styles.noContent}>
                                No content to display, please try another filter.
                            </div> :
                            <NewsSelector contentItems={content}
                                          aboveTheFold={props.aboveTheFold}
                                          viewMoreButton={props.blockObj.content_data[currentOrgId].is_more ?
                                              <ViewMoreButton text={"View More News"}
                                                              terraceType={props.blockObj.type}
                                                              organizationId={currentOrgId ? currentOrgId : organizationStore.currentOrganization.id}
                                                              className={styles.viewMoreButton}/> : undefined}
                                          getItemAtIndex={idx => {
                                              return content.length >= idx ? new ReactiveContentItem(content[idx]) : null;
                                          }}
                                          totalItemCount={content.length}
                                          className={styles.newsSelectorClassName}
                                          handleDelete={handleDelete}/>
                        }
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
})

export default NewsBlock;
